import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { TextArea, TextField, Label } from '../UI/FormComponent';
import FormButton from '../UI/FormButton';
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';
import Spinner from '../UI/Spinner';

function getCurrentDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
}
function CreditDebit() {


    const {
        token,
        loading,
        InsertCreditDebit,
        creditdebitList,
        currentPage,
        validateAmount,
        PieChartData,
        creditlist
    } = useContext(AppContext);

    const navigate = useNavigate();
    const id = useParams();
    const tempdata = {
        token: token,
        apikey: "Unique@1234567890@",
        Month: new Date().toLocaleString('en-US', { month: '2-digit' }),
        Year: new Date().getFullYear().toString()
    };

    const [creditDebit, setCreditDebit] = useState({
        CreditDebitID: "",
        token: "",
        apikey: "",
        Name: "",
        CompanyName: "",
        ContactNumber: "",
        Email: "",
        Amount: "",
        Description: "",
        CreditDebit: "",
        Created: ""
    });

    const [formTouched, setFormTouched] = useState({
        Amount: false,
        Name: false,
        CompanyName: false,
        ContactNumber: false,
        Description: false,
        CreditDebit: false
    });

    useEffect(() => {
        if (id.id) {
            const creditListEdit = creditdebitList.find(trans => trans.CreditDebitID === id.id);
            if (creditListEdit) {
                const dateOnly = creditListEdit.Created.toString().split(' ')[0];

                setCreditDebit({
                    CreditDebitID: creditListEdit.CreditDebitID,
                    Name: creditListEdit.Name,
                    CompanyName: creditListEdit.CompanyName,
                    ContactNumber: creditListEdit.ContactNumber,
                    Email: creditListEdit.Email,
                    Amount: creditListEdit.Amount,
                    Description: creditListEdit.Description,
                    CreditDebit: creditListEdit.CreditDebit,
                    Created: dateOnly
                })
            }
        }
        if (!id.id) {
            setCreditDebit(prev => ({
                ...prev,
                Created: getCurrentDate()
            }))
        }
    }, [id, creditlist]);


    const ChangeHandler = (event) => {
        const { name, value } = event.target;

        setCreditDebit((prev) => ({
            ...prev,
            [name]: value,
            token: token,
            apikey: "Unique@1234567890@",
        }));
        setFormTouched((prev) => ({
            ...prev,
            [event.target.name]: true,
        }));
    };

    const getInputClass = (field, isValid) => {
        if (!formTouched[field]) return "";
        // return isValid ? "border-success" : "border-danger";
        return isValid ? "is-valid" : "is-invalid";
    };
    const SubmitHandler = async (event) => {
        event.preventDefault();
        try {
            if (!creditDebit.Amount.trim()) {
                toast.error("Amount is required. Please enter a value to proceed.", { position: "top-right" });
                return;
            }
            if (!validateAmount(creditDebit.Amount)) {
                toast.error("Please enter a valid amount (only numbers and float allowed).", { position: "top-right" });
                return;
            }
            if (!creditDebit.Name.trim()) {
                toast.error("Name is required. Please provide details to proceed.", { position: "top-right" });
                return;
            }
            if (!creditDebit.CompanyName.trim()) {
                toast.error("Company Name is required. Please provide details to proceed.", { position: "top-right" });
                return;
            }
            if (!creditDebit.ContactNumber.trim()) {
                toast.error("Company Number is required. Please provide details to proceed.", { position: "top-right" });
                return;
            }
            if (!creditDebit.Description.trim()) {
                toast.error("Description is required. Please provide details to proceed.", { position: "top-right" });
                return;
            }
            if (!creditDebit.CreditDebit.trim()) {
                toast.error("Please select Credit or Debit to continue.", { position: "top-right" });
                return;
            }
            if (!/^[0-9]*(\.[0-9]+)?$/.test(creditDebit.Amount)) {
                toast.error("Please enter a valid amount (only numbers and float allowed).", { position: "top-right" });
                return;
            }
            await InsertCreditDebit(creditDebit);
            if (creditDebit.CreditDebit === "1") {
                navigate(`/creditlist?page=${currentPage}`);
            } else if (creditDebit.CreditDebit === "0") {
                navigate(`/debitlist?page=${currentPage}`);
            }
            PieChartData(tempdata);
            setCreditDebit({
                Name: "",
                CompanyName: "",
                ContactNumber: "",
                Email: "",
                Amount: "",
                Description: "",
                CreditDebit: "",
                Created: getCurrentDate()
            });
        } catch (error) {
            console.error("An error occurred while submitting data:", error);
            toast.error("An error occurred. Please try again.", { position: "top-right" });
        }
    };


    const cancleHandler = (event) => {
        event.preventDefault();
        toast.error("Cancellation successful. No changes have been saved.", { position: "top-right" });
        navigate('/dashboard');
    }



    return (
        <div>
            <div id="kt_content_container" className="container-xxl mb-4">
                <form onSubmit={SubmitHandler} className='needs-validation' novalidate encType="multipart/form-data">
                    <div className="card card-flush shadow-sm ">
                        <div className="card-header py-0">
                            <h3 className="card-title fw-bolder">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-plus-square" viewBox="0 0 16 16">
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>&nbsp;
                                Credit & Debit
                            </h3>
                            <Link to="/creditlist" className="card-title cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor"
                                    className="bi bi-list-task" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                        d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                                    <path
                                        d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                                    <path fillRule="evenodd"
                                        d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                                </svg>&nbsp; Credit & Debit  List
                            </Link>
                        </div>
                        <div className="card-body py-1 font-weight-bolder">
                            <div className="container">
                                <div className="mb-5 fv-row fv-plugins-icon-container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div>
                                                <Label className="required" labelText="Amount :" />
                                                <TextField
                                                    type="text"
                                                    id="Amount"
                                                    name="Amount"
                                                    value={creditDebit.Amount}
                                                    onChange={ChangeHandler}
                                                    className={`form-control mb-3 ${getInputClass("Amount", validateAmount(creditDebit.Amount) && creditDebit.Amount.trim() !== '')} `}
                                                    placeholder="Amount ₹"
                                                    requiredd={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div>
                                                <Label className="required" labelText="Contact Person Name :" />
                                                <TextField
                                                    type="text"
                                                    id="Name"
                                                    name="Name"
                                                    value={creditDebit.Name}
                                                    onChange={ChangeHandler}
                                                    className={`form-control mb-3 ${getInputClass("Name", creditDebit.Name.trim() !== "")}`}
                                                    placeholder="Contact Person Name :"
                                                    requiredd={false}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <Label className="required" labelText="Company Name :" />
                                                <TextField
                                                    type="text"
                                                    id="CompanyName"
                                                    name="CompanyName"
                                                    value={creditDebit.CompanyName}
                                                    onChange={ChangeHandler}
                                                    className={`form-control mb-1 ${getInputClass("CompanyName", creditDebit.CompanyName.trim() !== "")}`}
                                                    placeholder="Company Name :"
                                                    requiredd={false}
                                                />
                                            </div>
                                        </div>
                                        {
                                            loading
                                                ? <div className='col-md-12 d-flex justify-content-center w-0'>
                                                    <Spinner />
                                                </div>
                                                : (<p></p>)
                                        }
                                        <div className="col-md-6">
                                            <div>
                                                <Label className="required" labelText="Phone Number :" />
                                                <TextField
                                                    type="text"
                                                    id="ContactNumber"
                                                    name="ContactNumber"
                                                    value={creditDebit.ContactNumber}
                                                    onChange={ChangeHandler}
                                                    className={`form-control mb-2 ${getInputClass("ContactNumber", creditDebit.ContactNumber.trim() !== "")}`}
                                                    placeholder="Phone Number"
                                                    requiredd={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div>
                                                <Label className="" labelText="Emails :" />
                                                <TextField
                                                    type="email"
                                                    id="Email"
                                                    name="Email"
                                                    value={creditDebit.Email}
                                                    onChange={ChangeHandler}
                                                    className={`form-control mb-2 `}
                                                    placeholder="Emails"
                                                    requiredd={false}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <Label className="required" labelText="Details :" />
                                            <TextArea
                                                type="text"
                                                id="Description"
                                                name="Description"
                                                value={creditDebit.Description}
                                                onChange={ChangeHandler}
                                                className={`form-control mb-1 ${getInputClass("Description", creditDebit.Description.trim() !== "")}`}
                                                placeholder="Details"
                                                requiredd={false}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div>
                                                <Label className="" labelText="Date :" />
                                                <TextField
                                                    type="date"
                                                    id='Created'
                                                    name='Created'
                                                    value={creditDebit.Created}
                                                    onChange={ChangeHandler}
                                                    className={`form-control mb-2 cursor-pointer `}
                                                    required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Label className="required ms-2 mt-3" labelText="Type :" />
                                            <div >
                                                <div className=" ms-3 form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        id="1"
                                                        name="CreditDebit"
                                                        value="1"
                                                        className="form-check-input"
                                                        checked={creditDebit.CreditDebit === "1"}
                                                        onChange={ChangeHandler}
                                                    />
                                                    <Label labelText="Credit " className="form-check-label" />
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        id="0"
                                                        name="CreditDebit"
                                                        value="0"
                                                        className="form-check-input"
                                                        checked={creditDebit.CreditDebit === "0"}
                                                        onChange={ChangeHandler} />
                                                    <Label labelText="Debit " className="form-check-label" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormButton
                                onclick={cancleHandler}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreditDebit