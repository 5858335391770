import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { TextArea, TextField, Select, Label } from '../UI/FormComponent';
import FormButton from '../UI/FormButton';
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';
import Spinner from '../UI/Spinner';



function getCurrentDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
}

export function Withdrawal() {

    const navigate = useNavigate();

    const { token,
        withdrawalOff,
        paymentMethod,
        SelectWithdrawalOff,
        SelectPaymentMethod,
        InsertTransaction,
        allWithdrawal,
        UpdateTransaction,
        loading,
        currentPage,
        validateAmount,
        PieChartData } = useContext(AppContext);

    const id = useParams();
    const tempdata = {
        token: token,
        apikey: "Unique@1234567890@",
        Month: new Date().toLocaleString('en-US', { month: '2-digit' }),
        Year: new Date().getFullYear().toString()
      };

    const [withdrawal, setWithdrawal] = useState({
        token: "",
        apikey: "",
        Amount: "",
        Note: "",
        WithdrawalID: "",
        PaymentMethodID: "",
        Created: "",
        CategoryID: "null",
        PaymentTypeID: "null"
    });

    const [formTouched, setFormTouched] = useState({
        Amount: false,
        Note: false,
        WithdrawalID: false,
        PaymentMethodID: false
    });

    const ChangeHandler = (event) => {
        setWithdrawal((prev) => (
            {
                ...prev,
                [event.target.name]: event.target.value,
                token: token,
                apikey: "Unique@1234567890@",
            }
        ))

        setFormTouched((prev) => ({
            ...prev,
            [event.target.name]: true,
        }));
    };

    useEffect(() => {
        if (id.id) {
            const transactionToEdit = allWithdrawal.find(trans => trans.TransactionID === id.id);
            if (transactionToEdit) {
                const dateOnly = transactionToEdit.Created.toString().split(' ')[0];
                // console.log(dateOnly);
                setWithdrawal((prev) => ({
                    ...prev,
                    TransactionID: transactionToEdit.TransactionID,
                    Amount: transactionToEdit.Amount,
                    Note: transactionToEdit.Note,
                    WithdrawalID: transactionToEdit.WithdrawalID,
                    PaymentMethodID: transactionToEdit.PaymentMethodID,
                    Created: dateOnly,
                }));
            }
        }
        if (!id.id) {
            setWithdrawal(prev => ({
                ...prev,
                Created: getCurrentDate()
            }))
        }
    }, [id, allWithdrawal]);

    useEffect(() => {
        SelectWithdrawalOff();
        SelectPaymentMethod();
    }, [])

    const getInputClass = (field, isValid) => {
        if (!formTouched[field]) return "";
        // return isValid ? "border-success" : "border-danger";
        return isValid ? "is-valid" : "is-invalid";
    };

    const submitHandler = async (event) => {
        event.preventDefault();


        if (!withdrawal.Amount.trim()) {
            toast.error("Amount is required. Please enter a value to proceed.", { position: "top-right" });
            return;
        }
        if (!validateAmount(withdrawal.Amount)) {
            toast.error("Please enter a valid amount (only numbers and float allowed).", { position: "top-right" });
            return;
        }
        if (!withdrawal.Note.trim()) {
            toast.error("Description is required. Please provide details to proceed.", { position: "top-right" });
            return;
        }
        if (!withdrawal.WithdrawalID.trim()) {
            toast.error("Please choose WithDrawal to proceed.", { position: "top-right" });
            return;
        }
        if (!withdrawal.PaymentMethodID.trim()) {
            toast.error("Please select a Payment Method to continue.", { position: "top-right" });
            return;
        }
        if (!/^[0-9]*(\.[0-9]+)?$/.test(withdrawal.Amount)) {
            toast.error("Please enter a valid amount (only numbers and float allowed).", { position: "top-right" });
            return;
        }

        try {
            if (id.id) {
                // console.log(id.id);
                await UpdateTransaction(withdrawal);
            } else {
                await InsertTransaction(withdrawal);
            }
            navigate(`/withdrawallist?page=${currentPage}`);
            PieChartData(tempdata);

            setWithdrawal({
                Amount: "",
                Note: "",
                WithdrawalID: "",
                PaymentMethodID: "",
                Created: getCurrentDate()
            });

            setFormTouched({
                Amount: false,
                Note: false,
                WithdrawalID: false,
                PaymentMethodID: false
            });

        } catch (error) {
            toast.error("Something went wrong while submitting the transaction. Please try again.");
            // Optionally, display an error message to the user
        }
    }

    const cancleHandler = (event) => {
        event.preventDefault();
        toast.error("Cancellation successful. No changes have been saved.", { position: "top-right" });
        navigate('/dashboard');
    }


    return (
        <div id="kt_content_container" className="container-xxl mb-4">

            <div id="loader"></div>
            <form onSubmit={submitHandler}>
                <div className="card card-flush shadow-sm ">
                    <div className="card-header py-0">
                        <h3 className="card-title fw-bolder">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-plus-square" viewBox="0 0 16 16">
                                <path
                                    d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>&nbsp;
                            Withdrawal Add
                        </h3>

                        <Link to="/withdrawallist" className="card-title cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor"
                                className="bi bi-list-task" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                    d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                                <path
                                    d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                                <path fillRule="evenodd"
                                    d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                            </svg>&nbsp; Withdrawal List
                        </Link>
                    </div>
                    <div className="card-body py-1 font-weight-bolder">
                        <div className="container">
                            <div className="mb-5 fv-row fv-plugins-icon-container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div>
                                            <Label className="" labelText="Amount :" />
                                            <TextField
                                                id="Amount"
                                                type="text"
                                                name="Amount"
                                                value={withdrawal.Amount}
                                                onChange={ChangeHandler}
                                                className={`form-control mb-2 form-invalid-color ${getInputClass("Amount", validateAmount(withdrawal.Amount) && withdrawal.Amount.trim() !== '')}`}
                                                placeholder="Amount ₹"
                                                requiredd={false}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Label className="" labelText="Note :" />
                                        <TextArea
                                            type="text"
                                            id="Note"
                                            name="Note"
                                            value={withdrawal.Note}
                                            onChange={ChangeHandler}
                                            className={`form-control mb-2 form-invalid-color ${getInputClass("Note", withdrawal.Note.trim() !== "")}`}
                                            placeholder="Note"
                                            requiredd={false} />
                                    </div>
                                    {
                                        loading
                                            ? <div className='col-md-12 d-flex justify-content-center w-0'>
                                                <Spinner />
                                            </div>
                                            : (<p></p>)
                                    }
                                    <div className="col-md-6">
                                        <div>
                                            <Label className="" labelText="Withdrawal of :" />
                                            <Select
                                                id="WithdrawalID"
                                                className={`form-select form-select cursor-pointer color-black text-capitalize ${getInputClass("WithdrawalID", withdrawal.WithdrawalID.trim() !== "")}`}
                                                name="WithdrawalID"
                                                value={withdrawal.WithdrawalID}
                                                options={withdrawalOff}
                                                onChange={ChangeHandler}
                                                requiredd={false}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div>
                                            <Label className="" labelText="Payment Method :" />
                                            <Select
                                                id="PaymentMethodID"
                                                className={`form-select form-select cursor-pointer text-capitalize ${getInputClass("PaymentMethodID", withdrawal.PaymentMethodID.trim() !== "")} `}
                                                name="PaymentMethodID"
                                                value={withdrawal.PaymentMethodID}
                                                onChange={ChangeHandler}
                                                options={paymentMethod}
                                                requiredd={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div>
                                    <Label className="" labelText="Date :" />
                                    <TextField
                                        type="date"
                                        id="Created"
                                        name="Created"
                                        value={withdrawal.Created}
                                        onChange={ChangeHandler}
                                        className="form-control mb-2 cursor-pointer "
                                    />
                                </div>
                            </div>
                        </div>

                        <FormButton
                            onclick={cancleHandler} />
                    </div>
                </div>
            </form>
        </div>
    )
}
