import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

export const AppContext = createContext();

export default function AppContextProvider({ children }) {

    const [token, setToken] = useState(Cookies.get('token'));
    const [loading, setLoading] = useState(false);

    const [manueList, setManueList] = useState([]);
    const [userID, setUserID] = useState("");
    const [changeStatus, setChangeStatus] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!token);
    const [total, setTotal] = useState(0);

    const [creditdebitList, setCreditdebitList] = useState([]);
    const [creditlist, setCreditList] = useState([]);
    const [debitlist, setDebitList] = useState([]);
    const [allTransactions, setAllTransactions] = useState([]);
    const [allIncoming, setAllIncoming] = useState([]);
    const [allWithdrawal, setAllWithdrawal] = useState([]);
    const [allData, setAllData] = useState([]);

    const [paymentType, setPaymentType] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [category, setCategory] = useState([]);
    const [withdrawalOff, setwithdrawalOff] = useState([]);

    const [pieChart, setPieChart] = useState([]);
    const [lineChart, setLineChart] = useState({});
    const [barchart, setBarChart] = useState([]);
    const [currentMoney, setCurrentMoney] = useState([]);
    const [creditDebit, setCreditDebit] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);

    const [data, setData] = useState({
        token: token,
        apikey: "Unique@1234567890@"
    });

    useEffect(() => {
        const fetchData = async () => {
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    setUserID(decodedToken.UserID);
                    setManueList(decodedToken.manue || []);
                    setIsLoggedIn(true);

                    localStorage.setItem("token", token);
                    localStorage.setItem("manueList", JSON.stringify(decodedToken.manue || []));

                    await SelectAllTransaction();
                    await SelectCreditDebit();

                } catch (error) {
                    console.error("Token decoding failed:", error);
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
                localStorage.removeItem("token");
                localStorage.removeItem("manueList");
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const savedToken = localStorage.getItem("token");
        const savedManueList = JSON.parse(localStorage.getItem("manueList") || "[]");
        if (savedToken) {
            setToken(savedToken);
            setManueList(savedManueList);
            setIsLoggedIn(true);    
        }

    }, []);

    // useEffect(() => {
    //     if (token) {
    //         SelectAllTransaction();
    //         SelectCreditDebit();
    //         try {
    //             const decodedToken = jwtDecode(token);
    //             setUserID(decodedToken.UserID);
    //             setManueList(decodedToken.manue || []);
    //             setIsLoggedIn(true);
    //         }
    //         catch (error) {
    //             console.error("Token decoding failed:", error);
    //         }
    //     } else {
    //         setIsLoggedIn(false);
    //     }
    // }, [token]);

    const handleSetToken = (token) => {
        Cookies.set('token', token, { expires: 7, secure: true });
        setToken(token);
        setData(prevData => ({
            ...prevData,
            token: token,// This will always use the latest value of `token`
        }));
        setIsLoggedIn(true);
    };

    const SelectAllTransaction = async () => {
        try {

            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/selectTransaction.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(data),
            });
            const res_data = await response.json();
            // console.log(res_data);

            if (res_data.status) {
                setAllTransactions(res_data.data.transcation);
                setAllIncoming(res_data.data.incoming);
                setAllWithdrawal(res_data.data.withdrawal);
                setAllData(res_data.data.AllData);
            }
            else {
                // console.log(res_data);
                toast.error("Error from API", { position: "top-right" });
            }
        }
        catch (error) {
            console.error("Network Error : ", error);
            toast.error("Failed to fetch Transactions. Please try again later.", { position: "top-right" });

        }
    };

    const SelectPaymentType = async () => {
        try {
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/PaymentType.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(data),
            });

            const res_data = await response.json();
            // console.log(res_data);

            if (res_data.status) {
                setPaymentType(res_data.data);
            } else {
                // console.log(res_data);
                toast.error("Error from API", { position: "top-right" });
            }
        } catch (error) {
            console.error("Network Error:", error);
            toast.error("Failed to fetch payment types. Please try again later.", { position: "top-right" });
        }
    };

    const SelectPaymentMethod = async () => {
        try {
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/PaymentMethod.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(data),
            });
            // console.log(response);


            const res_data = await response.json();

            if (res_data.status) {
                setPaymentMethod(res_data.data);
                setChangeStatus(true);
            } else {
                // console.log(res_data);
                toast.error("Error from API", { position: "top-right" });
            }
        } catch (error) {
            console.error("Network Error:", error.message);
            toast.error("Failed to fetch payment methods. Please try again later.", { position: "top-right" });
        }
    };

    const SelectCategory = async () => {
        try {
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/Category.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(data),
            });


            const res_data = await response.json();

            if (res_data.status) {
                setCategory(res_data.data);
                setChangeStatus(true);
            } else {
                // console.log(res_data);
                toast.error("Error from API", { position: "top-right" });
            }
        } catch (error) {
            console.error("Network Error:", error.message);
            toast.error("Failed to fetch Category. Please try again later.", { position: "top-right" });
        }
    };

    const SelectWithdrawalOff = async () => {
        try {
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/withdrawal.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(data),
            });
            // console.log(response);

            const res_data = await response.json();
            if (res_data.status) {
                setwithdrawalOff(res_data.data);
                setChangeStatus(true);
            } else {
                // console.log(res_data);
                toast.error("Error from API", { position: "top-right" });
            }
        } catch (error) {
            console.error("Network Error:", error.message);
            toast.error("Failed to fetch Withdrawal Off options. Please try again later.", { position: "top-right" });
        }
    };

    const InsertTransaction = async (transactionData) => {
        // console.log(transactionData);
        try {
            setLoading(true);
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/insertUpdateData.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(transactionData),
            });
            const res_data = await response.json();
            if (res_data.status) {
                await SelectAllTransaction();
                toast.success("Data has been successfully added.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    icon: "✅"
                });
                setChangeStatus(true);
            }
            else {
                toast.error("Failed to add data. Please try again.", { position: "top-right" });
            }
            setLoading(false);
        }
        catch (error) {
            console.error("Network Error : ", error);
            toast.error("Unable to add data due to a network issue. Please try again later.", { position: "top-right" });
        }
    };

    const deleteHandler = async (id) => {
        try {
            const deleteData = {
                token: token,
                apikey: "Unique@1234567890@",
                TransactionID: id
            }
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/deletdata.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(deleteData),
            });
            const res_data = await response.json();
            // console.log(res_data);
            if (res_data.status) {
                await SelectAllTransaction();
                toast.success("Delete Successfully", { position: "top-right" });
                setChangeStatus(true);
            }
            else {
                toast.error("Error from API", { position: "top-right" });
            }
        } catch (error) {

        }
        // console.log(id);
    };

    const UpdateTransaction = async (transaction) => {
        try {
            setLoading(true);
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/insertUpdateData.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(transaction),
            });
            const res_data = await response.json();
            if (res_data.status) {
                // console.log(res_data);
                await SelectAllTransaction();
                toast.success("Your changes have been successfully updated.", { position: "top-right" });
                setChangeStatus(true);
            }
            else {
                toast.error("Update failed. Please try again.", { position: "top-right" });
            }
            setLoading(false);
        } catch (error) {
            console.error("Network Error : ", error);
            toast.error("Update failed due to a network error. Please try again later.", { position: "top-right" });

        }
    };

    const InsertCreditDebit = async (creditDebit) => {
        // console.log(creditDebit);
        try {
            setLoading(true);
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/insertUpdateCreditDebit.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(creditDebit),
            });
            const res_data = await response.json();
            if (res_data.status) {
                await SelectCreditDebit();
                toast.success("Data has been successfully added.", { position: "top-right" });
                setLoading(false);
                setChangeStatus(true);
            }
            else {
                toast.error("Failed to add data. Please try again.", { position: "top-right" });
            }
        }
        catch (error) {
            console.error("Network Error : ", error);
            toast.error("Unable to add data due to a network issue. Please try again later.", { position: "top-right" });
        }
    };

    const SelectCreditDebit = async () => {
        try {

            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/selectCreditDebit.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(data),
            });
            const res_data = await response.json();

            if (res_data.status) {
                setCreditdebitList(res_data.data.creditDebitList);
                setCreditList(res_data.data.Credit);
                setDebitList(res_data.data.Debit);
            }
            else {
                // console.log(res_data);
                toast.error("Error from API C", { position: "top-right" });
            }
        }
        catch (error) {
            console.error("Network Error : ", error);
            toast.error("Failed to fetch Credit Debit. Please try again later.", { position: "top-right" });

        }
    };

    const deleteCreditdebitHandler = async (id) => {
        try {
            const deleteData = {
                token: token,
                apikey: "Unique@1234567890@",
                CreditDebitID: id
            }
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/deleteCreditDebitData.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(deleteData),
            });
            const res_data = await response.json();
            if (res_data.status) {
                await SelectCreditDebit();
                // console.log(creditlist);     
                toast.success("Delete Successfully", { position: "top-right" });
                setChangeStatus(true);
            }
            else {
                toast.error("Error from API", { position: "top-right" });
            }
        } catch (error) {

        }
        // console.log(id);
    };

    const PieChartData = async (tempData) => {
        try {
            setLoading(true);
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/chart.php", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(tempData),
            });
            const res_data = await response.json();
            if (res_data.status) {
                // console.log(tempData);

                if (res_data.data?.piechart) {
                    // console.log(res_data.data);
                    const transformedData = [
                        ['Category', 'Amount'],
                        ...res_data.data.piechart.map(item => [item.Name, parseFloat(item.Amount)])
                    ];
                    // console.log(transformedData);
                    setPieChart(res_data.data.piechart);
                }
                else {
                    console.error("No pie chart data available");
                    setPieChart([]);
                }

                if (res_data.data?.linechart) {
                    const categories = res_data.data.linechart.map(item => item.Month);
                    const years = res_data.data.linechart.map(item => item.Year);
                    
                    const expensesData = res_data.data.linechart.map(item => parseFloat(item.Amout));
                    const incomeData = res_data.data.linechart.map(item => parseFloat(item.Income));

                    const linechartData = {
                        categories,
                        years,
                        series: [
                            {
                                name: 'Expenses',
                                data: expensesData,
                            },
                            {
                                name: 'Income',
                                data: incomeData,
                            }
                        ]
                    };

                    setLineChart(linechartData);
                } else {
                    console.error("No line chart data available");
                    setLineChart({});
                }
   
                if (res_data.data?.barchart) {
                     const transformedData = [
                         ['Category', 'Amount'],
                         ...res_data.data.barchart.map(item => [item.Month, parseFloat(item.Profit)])
                     ];
                     setBarChart(transformedData);
                     // console.log(pieChart);
                 } else {
                     console.error("No pie chart data available");
                     setPieChart([]); 
                 }

                if (res_data.data?.currentmoney) {
                    const transformedData = res_data.data.currentmoney.map(item => ({
                        Name: item.Name,
                        Current: parseFloat(item.CurrantMoney)
                    }));
                    setCurrentMoney(transformedData);

                    const totalSum = res_data.data.currentmoney.reduce((acc, item) => acc + parseFloat(item.CurrantMoney), 0);
                    setTotal(totalSum.toFixed(2));
                } else {
                    console.error("No pie chart data available");
                    setPieChart([]);
                }

                setCreditDebit(res_data.data.CreditDebit);

                setChangeStatus(true);
            } else {
                toast.error("Failed to Fetch Pie Chart Data.", { position: "top-right" });
            }

            setLoading(false);
        } catch (error) {
            console.error("Network Error: ", error);
            setLoading(false);
        }
    };



    const validateAmount = (value) => {
        const regex = /^[0-9]*(\.[0-9]+)?$/;
        return regex.test(value);
    };

    const value = {
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
        allTransactions,
        allIncoming,
        allWithdrawal,
        debitlist,
        creditlist,
        creditdebitList,
        allData,
        manueList,
        setManueList,
        userID,
        handleSetToken,
        paymentType,
        paymentMethod,
        category,
        withdrawalOff,
        changeStatus,
        loading,
        currentPage,
        setCurrentPage,
        pieChart,
        lineChart,
        barchart,
        currentMoney,
        creditDebit,
        total,


        SelectAllTransaction,
        SelectCategory,
        SelectPaymentType,
        SelectPaymentMethod,
        SelectWithdrawalOff,
        InsertTransaction,
        deleteHandler,
        UpdateTransaction,
        InsertCreditDebit,
        SelectCreditDebit,
        deleteCreditdebitHandler,
        PieChartData,
        validateAmount
    }


    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;

}
