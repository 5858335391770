// import React, { useState, useEffect, useContext } from 'react';
// import Chart from 'react-apexcharts';
// import { AppContext } from '../../context/AppContext';
// import '../UI/spinner.css'
// import { AiOutlineBarChart } from 'react-icons/ai';

// const LineChart = () => {
//     const { token, lineChart, PieChartData } = useContext(AppContext);
//     const [series, setSeries] = useState([]);

//     const [options, setOptions] = useState({
//         chart: {
//             height: 400,
//             type: 'line',
//             toolbar: {
//                 show: true,
//             },
//             zoom: {
//                 enabled: false,
//             },
//         },
//         title: {
//             text: ' ',
//             align: 'center',
//         },
//         colors: ['#FF4560', '#008FFB'],
//         dataLabels: {
//             enabled: false,
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 4,
//         },
//         xaxis: {
//             categories: [], // Initially empty; will be updated with fetched data
//             title: {
//                 text: 'Month',
//                 style: {
//                     fontSize: '14px',
//                     fontWeight: 600,
//                 },
//             },
//         },
//         yaxis: [
//             {
//                 title: {
//                     text: 'Expenses (INR)',
//                 },
//                 min: 0,
//             },
//             {
//                 opposite: true,
//                 title: {
//                     text: 'Income (INR)',
//                 },
//             },
//         ],
//         tooltip: {
//             shared: true,
//             intersect: false,
//         },
//         grid: {
//             borderColor: '#f1f1f1',
//         },
//     });


//     const tempData = {
//         token: token,
//         apikey: "Unique@1234567890@",
//         Month: "01",
//         Year: "2025",
//     };

//     useEffect(() => {
//         if (lineChart && lineChart.series && lineChart.categories) {
//             console.log(lineChart.series);

//             // Calculate the min and max for Expenses and Income
//             const expensesData = lineChart.series.find(item => item.name === 'Expenses')?.data || [];
//             const incomeData = lineChart.series.find(item => item.name === 'Income')?.data || [];

//             const expensesMax = Math.max(...expensesData);
//             const incomeMax = Math.max(...incomeData);

//             const expensesMin = Math.min(...expensesData);
//             const incomeMin = Math.min(...incomeData);

//             // Debug log
//             console.log("Expenses Range:", expensesMin, expensesMax);
//             console.log("Income Range:", incomeMin, incomeMax);

//             setSeries(lineChart.series);

//             setOptions(prevOptions => ({
//                 ...prevOptions,
//                 xaxis: {
//                     ...prevOptions.xaxis,
//                     categories: lineChart.categories,
//                 },
//                 yaxis: [
//                     {
//                         title: {
//                             text: 'Expenses (INR)',
//                         },
//                         min: Math.min(0, expensesMin - 10000), // Add buffer below
//                         max: expensesMax + 10000, // Add buffer above
//                     },
//                     {
//                         opposite: true,
//                         title: {
//                             text: 'Income (INR)',
//                         },
//                         min: Math.min(0, incomeMin - 10000),
//                         max: incomeMax + 10000,
//                     },
//                 ],
//             }));
//         } else {
//             console.error("No line chart data available");
//         }
//     }, [lineChart]);


//     // useEffect(() => {
//     //     if (lineChart && lineChart.series && lineChart.categories) {
//     //         console.log(lineChart.series);
//     //         setSeries(lineChart.series);
//     //         setOptions(prevOptions => ({
//     //             ...prevOptions,
//     //             xaxis: {
//     //                 ...prevOptions.xaxis,
//     //                 categories: lineChart.categories,
//     //             },
//     //         }));
//     //     } else {
//     //         console.error("No line chart data available");

//     //     }
//     // }, [lineChart]);



//     return (
//         <div style={{
//             height: '450px',
//             backgroundColor: '#fff',
//             borderRadius: '20px',
//             overflow: 'hidden',
//         }}>
//             {/* {Array.isArray(series) && series.length > 0 ? (
//                 <Chart options={options} series={series} type="line" height={450} />
//             ) : (
//                 <div className='d-flex align-items-center justify-content-center h-100'>
//                     <span className="loader"></span>
//                 </div>
//             )} */}
//             {series === null || series === undefined ? (
//                 <div className='d-flex align-items-center justify-content-center h-100'>
//                     <span className="loader"></span>
//                 </div>
//             ) : series.length === 0 || (series[0].data && series[0].data.length === 0) ? (
//                 <div className='d-flex align-items-center justify-content-center h-100 flex-column'>
//                     <AiOutlineBarChart size={40} color="#ccc" style={{ marginBottom: '10px' }} />
//                     <p className='fs-2' style={{ textAlign: 'center', fontSize: '18px', color: '#666' }}>
//                         No data found
//                     </p>
//                 </div>
//             ) : (
//                 <Chart
//                     options={options}
//                     series={series}
//                     type="line"
//                     height={450}
//                 />
//             )}

//         </div>
//     );
// };

// export default LineChart;

// import React, { useState, useEffect, useContext } from 'react';
// import Chart from 'react-apexcharts';
import { AppContext } from '../../context/AppContext';
// import '../UI/spinner.css';
// import { AiOutlineBarChart } from 'react-icons/ai';

// const LineChart = () => {
//     const { lineChart } = useContext(AppContext);
//     const [chartOptions, setChartOptions] = useState({});
//     const [chartSeries, setChartSeries] = useState([]);

//     useEffect(() => {
//         if (lineChart && lineChart.series && lineChart.categories) {
//             const categories = lineChart.categories;
//             const expensesData = lineChart.series.find(item => item.name === 'Expenses')?.data || [];
//             const incomeData = lineChart.series.find(item => item.name === 'Income')?.data || [];
//             console.log(lineChart);
//             // Configure ApexCharts options and series
//             setChartOptions({
//                 chart: {
//                     type: 'line',
//                     height: 450,
//                     toolbar: {
//                         show: false, // Hides toolbar
//                     },
//                 },
//                 stroke: {
//                     curve: 'smooth', // Smooth curves
//                     width: 2,
//                 },
//                 colors: ['#FF4560', '#008FFB'], // Line colors
//                 title: {
//                     text: 'Monthly Financial Overview',
//                     align: 'center',
//                     style: {
//                         fontSize: '18px',
//                         fontWeight: 'bold',
//                         color: '#333',
//                     },
//                 },
//                 xaxis: {
//                     categories: categories,
//                     title: {
//                         text: 'Month',
//                         style: {
//                             fontSize: '14px',
//                             fontWeight: 'bold',
//                             color: '#333',
//                         },
//                     },
//                     labels: {
//                         style: {
//                             fontSize: '12px',
//                             color: '#555',
//                         },
//                     },
//                 },
//                 yaxis: {
//                     title: {
//                         text: 'Amount (INR)',
//                         style: {
//                             fontSize: '14px',
//                             fontWeight: 'bold',
//                             color: '#333',
//                         },
//                     },
//                     labels: {
//                         style: {
//                             fontSize: '12px',
//                             color: '#555',
//                         },
//                     },
//                     // tickAmount: Math.ceil((Math.max(...lineChart.series[0].data) || 0) / 50000), 
//                 },
//                 legend: {
//                     position: 'top',
//                     horizontalAlign: 'right',
//                     labels: {
//                         colors: '#444',
//                     },
//                 },
//                 grid: {
//                     borderColor: '#eee',
//                     strokeDashArray: 5,
//                 },
//                 tooltip: {
//                     theme: 'light',
//                     y: {
//                         formatter: value => {
//                             if (value === undefined || value === null) {
//                                 return "N/A"; 
//                             }
//                             return `₹ ${value.toLocaleString()}`;
//                         },
//                     },
//                 },
//             });

//             setChartSeries([
//                 {
//                     name: 'Expenses',
//                     data: expensesData,
//                 },
//                 {
//                     name: 'Income',
//                     data: incomeData,
//                 },
//             ]);
//         } else {
//             console.error('No line chart data available');
//         }
//     }, [lineChart]);

//     return (
//         <div
//             style={{
//                 height: '450px',
//                 backgroundColor: '#fff',
//                 borderRadius: '20px',
//                 overflow: 'hidden',
//                 padding: '20px',
//                 boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//             }}
//         >
//             {chartSeries.length > 0 ? (
//                 <Chart
//                     options={chartOptions}
//                     series={chartSeries}
//                     type="line"
//                     height="400px"
//                 />
//             ) : (
//                 <div className="d-flex align-items-center justify-content-center h-100 flex-column">
//                     <AiOutlineBarChart size={40} color="#ccc" style={{ marginBottom: '10px' }} />
//                     <p
//                         className="fs-2"
//                         style={{
//                             textAlign: 'center',
//                             fontSize: '18px',
//                             color: '#666',
//                         }}
//                     >
//                         No data found
//                     </p>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default LineChart;
import React, { useState, useEffect, useContext } from 'react';
import { AiOutlineBarChart } from 'react-icons/ai';
import Chart from 'react-apexcharts';

const LineChart = () => {
  const { lineChart } = useContext(AppContext);  // Get lineChart from context
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2025'); // Default to 2025

  useEffect(() => {
    if (lineChart?.categories && lineChart?.series && lineChart?.years) {
      const availableYears = Array.from(new Set(lineChart.years)); // Unique years
  
      // Get indexes where years match the selected year
      const matchingIndexes = lineChart.years
        .map((year, idx) => (year === selectedYear ? idx : -1))
        .filter(idx => idx !== -1);
  
      // Filter series data based on selected year
      const updatedSeries = lineChart.series.map(entry => ({
        ...entry,
        data: matchingIndexes.map(idx => entry.data[idx]),
      }));
  
      // Filter categories and years accordingly
      const updatedCategories = matchingIndexes.map(idx => lineChart.categories[idx]);
      const updatedYears = matchingIndexes.map(idx => lineChart.years[idx]);
  
      // Final structured data
      const filteredChartData = {
        categories: updatedCategories,
        years: updatedYears,
        series: updatedSeries,
      };
  
      // Update chart options
      setChartOptions({
        chart: {
          type: 'line',
          height: 450,
          toolbar: { show: false },
        },
        stroke: { curve: 'smooth', width: 2 },
        colors: ['#FF4560', '#008FFB'],
        title: {
          text: `Financial Overview for ${selectedYear}`,
          align: 'center',
          style: { fontSize: '18px', fontWeight: 'bold', color: '#333' },
        },
        xaxis: {
          categories: updatedCategories, // Ensure filtered categories are used
          title: { text: 'Month', style: { fontSize: '14px', fontWeight: 'bold', color: '#333' } },
          labels: { style: { fontSize: '12px', color: '#555' } },
        },
        yaxis: {
          title: { text: 'Amount (INR)', style: { fontSize: '14px', fontWeight: 'bold', color: '#333' } },
          labels: { style: { fontSize: '12px', color: '#555' } },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          labels: { colors: '#444' },
        },
        grid: { borderColor: '#eee', strokeDashArray: 5 },
        tooltip: {
          theme: 'light',
          y: {
            formatter: value => (value !== undefined && value !== null ? `₹ ${value.toLocaleString()}` : 'N/A'),
          },
        },
      });
  
      setChartSeries(filteredChartData.series); // Set the filtered series data
  
    } else {
      console.error('Line chart data is missing or incomplete.');
    }
  }, [lineChart, selectedYear]);
  
  // Handle year selection change
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  // Get available years from the lineChart data and remove duplicates
  const availableYears = Array.from(new Set(lineChart.years || []));

  return (
    <div
      style={{
        height: '450px',
        backgroundColor: '#fff',
        borderRadius: '20px',
        overflow: 'hidden',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      }}
    >
      <div style={{ marginBottom: '20px' }}>
        {availableYears.length > 0 && (
          <>
            <label htmlFor="year-select" style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Select Year:
            </label>
            <select
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
              style={{
                padding: '5px 10px',
                fontSize: '14px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            >
              {availableYears.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </>
        )}
      </div>

      {selectedYear && chartSeries.length > 0 ? (
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height="400px"
        />
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100 flex-column">
          <AiOutlineBarChart size={40} color="#ccc" style={{ marginBottom: '10px' }} />
          <p
            className="fs-2"
            style={{
              textAlign: 'center',
              fontSize: '18px',
              color: '#666',
            }}
          >
            No data found
          </p>
        </div>
      )}
    </div>
  );
};

export default LineChart;
