import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { TextArea, TextField, Select, Label } from '../UI/FormComponent';
import FormButton from '../UI/FormButton';
import { AppContext } from '../../context/AppContext';
import Spinner from '../UI/Spinner';
import { Link } from 'react-router-dom';


function getCurrentDate() {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const dd = String(today.getDate()).padStart(2, '0');

  return `${yyyy}-${mm}-${dd}`;
}

export function Transaction() {


  const navigate = useNavigate();
  const id = useParams();

  const {
    token,
    category,
    paymentMethod,
    allTransactions,
    SelectCategory,
    SelectPaymentMethod,
    InsertTransaction,
    UpdateTransaction,
    loading,
    currentPage,
    PieChartData,
    validateAmount
  } = useContext(AppContext);

  const [transaction, setTransaction] = useState({
    TransactionID: "",
    token: "",
    apikey: "",
    Amount: "",
    Note: "",
    CategoryID: "",
    PaymentMethodID: "",
    Created: "",
    PaymentTypeID: "null",
    WithdrawalID: "null"
  });

  const [formTouched, setFormTouched] = useState({
    Amount: false,
    Note: false,
    CategoryID: false,
    PaymentMethodID: false
  });

  useEffect(() => {
    if (id.id) {
      const transactionToEdit = allTransactions.find(trans => trans.TransactionID === id.id);
      if (transactionToEdit) {
        const dateOnly = transactionToEdit.Created.toString().split(' ')[0];
        setTransaction((prev) => ({
          ...prev,
          TransactionID: transactionToEdit.TransactionID,
          Amount: transactionToEdit.Amount,
          Note: transactionToEdit.Note,
          CategoryID: transactionToEdit.CategoryID,
          PaymentMethodID: transactionToEdit.PaymentMethodID,
          Created: dateOnly,
        }));
      }
    }
    if (!id.id) {
      setTransaction(prev => ({
        ...prev,
        Created: getCurrentDate()
      }))
    }
  }, [id, allTransactions]);

  useEffect(() => {
    SelectCategory();
    SelectPaymentMethod();
  }, []);


  const ChangeHandler = (event) => {
    setTransaction((prev) => (
      {
        ...prev, [event.target.name]: event.target.value,
        token: token,
        apikey: "Unique@1234567890@",
      }
    ))

    setFormTouched((prev) => ({
      ...prev,
      [event.target.name]: true,
    }));
  };

  const getInputClass = (field, isValid) => {
    if (!formTouched[field]) return "";
    // return isValid ? "border-success" : "border-danger";
    return isValid ? "is-valid" : "is-invalid";
  };

  const submitHandler = async (event) => {
    event.preventDefault();


    const tempdata = {
      token: token,
      apikey: "Unique@1234567890@",
      Month: new Date().toLocaleString('en-US', { month: '2-digit' }),
      Year: new Date().getFullYear().toString()
    };

    if (!transaction.Amount.trim()) {
      toast.error("Amount is required. Please enter a value to proceed.", { position: "top-right" });
      return;
    }
    if (!validateAmount(transaction.Amount)) {
      toast.error("Please enter a valid amount (only numbers and float allowed).", { position: "top-right" });
      return;
    }
    if (!transaction.Note.trim()) {
      toast.error("Description is required. Please provide details to proceed.", { position: "top-right" });
      return;
    }
    if (!transaction.CategoryID.trim()) {
      toast.error("Please choose Category to proceed.", { position: "top-right" });
      return;
    }
    if (!transaction.PaymentMethodID.trim()) {
      toast.error("Please select a Payment Method to continue.", { position: "top-right" });
      return;
    }
    if (!/^[0-9]*(\.[0-9]+)?$/.test(transaction.Amount)) {
      toast.error("Please enter a valid amount (only numbers and float allowed).", { position: "top-right" });
      return;
    }

    try {

      if (id.id) {
        await UpdateTransaction(transaction);
      }
      else {
        await InsertTransaction(transaction);
      }
      navigate(`/transactionlist?page=${currentPage}`);

      PieChartData(tempdata);

      setTransaction({
        Amount: "",
        Note: "",
        CategoryID: "",
        PaymentMethodID: "",
        Created: getCurrentDate()
      });

      setFormTouched({
        Amount: false,
        Note: false,
        PaymentTypeID: false,
        PaymentMethodID: false
      });


    } catch (error) {
      console.error("Error during transaction submission:", error);
      toast.error("Something went wrong while submitting the transaction. Please try again.");
    }

  };


  const cancleHandler = (event) => {
    event.preventDefault();
    toast.error("Cancellation successful. No changes have been saved.", { position: "top-right" });
    navigate('/dashboard');
  }


  return (
    <div id="kt_content_container" className="container-xxl mb-4">
      {/* <div id="loader"></div>  */}
      <form onSubmit={submitHandler} encType="multipart/form-data">

        <div className="card card-flush shadow-sm ">
          <div className="card-header py-0">
            <h3 className="card-title fw-bolder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                className="bi bi-plus-square" viewBox="0 0 16 16">
                <path
                  d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2
                     2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0
                     1 8 4z" />
              </svg>&nbsp;
              Add Outgoing
            </h3>

            <Link to="/transactionlist" className="card-title cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor"
                className="bi bi-list-task" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                  d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                <path
                  d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                <path fillRule="evenodd"
                  d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
              </svg>&nbsp; Outgoing List
            </Link>
          </div>


          <div className="card-body py-1 font-weight-bolder">
            <div className="container">
              <div className="mb-5 fv-row fv-plugins-icon-container">
                <div className="row">

                  <div className="col-md-12">
                    <div>
                      <Label className="" labelText="Amount :" />
                      <TextField
                        type="text"
                        id="Amount"
                        name="Amount"
                        value={transaction.Amount}
                        onChange={ChangeHandler}
                        className={`form-control mb-2 ${getInputClass("Amount", validateAmount(transaction.Amount) && transaction.Amount.trim() !== '')}`}
                        placeholder="Amount ₹"
                        requiredd={false} />
                    </div>
                  </div>
                  <div>
                    <Label className="" labelText="Note :" />
                    <TextArea
                      type="text"
                      id="Note"
                      name="Note"
                      value={transaction.Note}
                      onChange={ChangeHandler}
                      className={`form-control mb-2 ${getInputClass("Note",  transaction.Note.trim() !== "")} `}
                      placeholder="Note"
                      requiredd={false} />
                  </div>
                  {
                    loading
                      ? <div className='col-md-12 d-flex justify-content-center w-0'>
                        <Spinner />
                      </div>
                      : (<p></p>)
                  }
                  <div className="col-md-6">
                    <div>
                      <Label className="" labelText="Category :" />
                      <Select
                        id="CategoryID"
                        name="CategoryID"
                        className={`form-select form-select cursor-pointer color-black text-capitalize ${getInputClass("CategoryID",  transaction.CategoryID.trim() !== "")}`}
                        options={category}
                        value={transaction.CategoryID}
                        requiredd={false}
                        onChange={ChangeHandler}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <Label className="" labelText="Payment Method :" />
                      <Select
                        id="PaymentMethodID"
                        className={`form-select form-select cursor-pointer text-capitalize ${getInputClass("PaymentMethodID",  transaction.PaymentMethodID.trim() !== "")} `}
                        name="PaymentMethodID"
                        options={paymentMethod}
                        value={transaction.PaymentMethodID}
                        onChange={ChangeHandler}
                        requiredd={false}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div>
                  <Label className="" labelText="Date :" />
                  <TextField
                    type="date"
                    id='Created'
                    name='Created'
                    value={transaction.Created}
                    onChange={ChangeHandler}
                    className="form-control mb-2 cursor-pointer "
                  />
                </div>
              </div>

            </div>

            <FormButton
              onclick={cancleHandler} />
          </div>
        </div>
      </form>
    </div>
  )
}
