import React, { useContext, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { AppContext } from '../../context/AppContext';
import ApexCharts from 'react-apexcharts';

function ChartsJS() {
    const { token, pieChart, PieChartData } = useContext(AppContext);

    const tempData = {
        token: token,
        apikey: "Unique@1234567890@",
        Month: new Date().toLocaleString('en-US', { month: '2-digit' }),
        Year: new Date().getFullYear().toString()
    };

    // Fetch Pie Chart Data on component mount
    // useEffect(() => {
    //     PieChartData(tempData);
    // }, [token]);

    // Fallback data in case pieChart is not ready
    const fallbackPieChart = [
        ["Category", "Amount"],
        ["Bigrock Domain", 1143.42],
        ["Freelancing", 30000.00],
        ["Leti-Deti", 14054.00],
        ["Nasto", 434.00],
        ["Other", 140.00],
    ];

    // Use fallback data if pieChart is undefined or empty
    const chartData = pieChart.length > 1 ? pieChart : fallbackPieChart;

    const options = {
        chart: {
            height: 500,
            type: 'donut',
            offsetY: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '40%',
                }
            }
        },
        stroke: {
            show: false,
            width: 0
        },
        colors: ['#FFA500', '#00FFFF', '#4B0082'], // Replace with your colors
        series: [48, 30, 22],
        labels: ['Social Media', 'Google', 'Email'],
        legend: {
            show: false
        }
    };


    const options2 = {
        series: [
            {
                name: "Sales",
                data: [65, 60, 62, 69, 71, 65, 68, 67, 60, 61, 59, 64]
            },
            {
                name: 'Orders',
                data: [78, 75, 73, 78, 75, 73, 77, 74, 75, 77, 71, 75]
            }
        ],
        chart: {
            height: 550,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#FF4560', '#008FFB'], // Replace with your color variables
        stroke: {
            width: 4,
            curve: 'smooth',
        },
        legend: {
            show: false
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: ['01 May', '02 May', '03 May', '04 May', '05 May', '06 May', '07 May', '08 May', '09 May', '10 May', '11 May', '12 May'],
        },
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        }
                    }
                },
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        }
                    }
                }
            ]
        },
        grid: {
            borderColor: '#e0e0e0', // Replace with your color variable
        }
    };
    return (
            <div id="kt_content_container" className="container-xxl my-auto">

                <div className="d-flex flex-row justify-content-center align-items-start mb-5">
                    <div className="me-4">
                        <div className="fs-2 fw-bolder ms-2 mb-5">Monthly Financial Overview :
                            <ApexCharts options={options2} series={options2.series} type="line" height={350} width={550} />
                        </div>
                    </div>
                    <div className="me-4">
                        <div className="fs-2 fw-bolder ms-2 mb-5">Monthly Financial Overview :
                            <ApexCharts options={options} series={options.series} type="donut" height={500} width={350} />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-between align-items-start mb-5">

                </div>
            </div>
    );
}

export default ChartsJS;
